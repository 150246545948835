








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import CallToAction from '../../components/6-other/call-to-action.vue';

@Component({
  components: { CallToAction },
})
export default class Method extends Vue {
  public get fields() {
    return [
      {
        key: 'feature',
        label: '',
        thClass: 'th-module',
        tdClass: 'td-module',
      },
      {
        key: 'free',
        label: 'Gratis',
        thClass: 'th-license',
        tdClass: 'td-license',
      },
      {
        key: 'basic',
        label: 'Basic',
        thClass: 'th-license',
        tdClass: 'td-license',
      },
      {
        key: 'plus',
        label: 'Plus',
        thClass: 'th-license',
        tdClass: 'td-license',
      },
      {
        key: 'pro',
        label: 'Pro',
        thClass: 'th-license',
        tdClass: 'td-license',
      },
    ];
  }

  public get items() {
    return [
      {
        feature: 'Algemene vragen',
        free: '✓',
        basic: '✓',
        plus: '✓',
        pro: '✓',
      },
      { feature: 'Relatie', free: '✓', basic: '✓', plus: '✓', pro: '✓' },
      { feature: 'Strategie', free: '', basic: '✓', plus: '✓', pro: '✓' },
      {
        feature: 'Winstgevendheid',
        free: '',
        basic: '✓',
        plus: '✓',
        pro: '✓',
      },
      {
        feature: 'Samenwerkingscontract',
        free: '',
        basic: '✓',
        plus: '✓',
        pro: '✓',
      },
      {
        feature: 'Werving & Selectie',
        free: '',
        basic: '✓',
        plus: '✓',
        pro: '✓',
      },
      {
        feature: 'Maximaal 5 zelf geformuleerde vragen',
        free: '',
        basic: '',
        plus: '✓',
        pro: '✓',
      },
      {
        feature: 'Vragenlijst voor medewerkers hoofdkantoor',
        free: '',
        basic: '',
        plus: '',
        pro: '✓',
      },
    ];
  }
}
